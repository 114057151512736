import React from "react"
import styled from "styled-components"
import { VSection } from "components/common"

// import { ContactUsForm } from "components/forms"

const ContactFormHero = ({ title, subtitle, url }) => {
  // const renderForm = () => (
  //   <div className="formWrap">
  //     <ContactUsForm {...url} />
  //   </div>
  // )

  return (
    <StyledContactUsForm>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        // custom={renderForm}
      />
    </StyledContactUsForm>
  )
}

const StyledContactUsForm = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  .v_tgContainer {
    --tg-text-align: left;
    --section-align: left;
    max-width: 700px;
    margin: 0 auto;

    img {
      width: 100%;
      max-width: 350px;
      height: auto;
      transform: translateX(-25px);
    }
  }

  .formWrap {
    /* margin-top: var(--sp-24); */
  }

  .v_customContainer {
    --section-mobile-padding-left: 0;
    --section-mobile-padding-right: 0;
  }
`

export default ContactFormHero
