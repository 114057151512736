import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta, useContentfulComponent } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import ContactFormHero from "src/sections/contact/ContactFormHero.js"

const FreeSupportPage = ({ data: { support } }) => {
  const meta = useMeta(support)

  const { free_support_form } = useContentfulSection(support.sections)

  const { free_support_form_url } = useContentfulComponent(
    free_support_form.components
  )

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />

      <FreeSupportFormWrap>
        <ContactFormHero {...free_support_form} url={free_support_form_url} />
      </FreeSupportFormWrap>
    </Layout>
  )
}

const FreeSupportFormWrap = styled.div``

export const query = graphql`
  {
    support: contentfulPage(pageId: { eq: "support" }) {
      ...Page
    }
  }
`

export default FreeSupportPage
